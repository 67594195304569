.app {
  min-height: 100vh !important;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 140px;
  background: white !important;
}

.loading {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  .imageWrapper {
    text-align: center;

    img {
      max-height: 105px;
    }
  }
}

.errorMsg {
  font-size: 1.2em;
  padding: 10px;
  text-align: left;
}

.headerContainer {
  background-color: #ffffff;
  box-shadow: 0 9px 10px -7px rgba(185, 185, 185, 0.75);
}
